import { FormattedMessage } from 'react-intl';
import {
  CalendarOutlined,
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  RocketOutlined,
  TagsOutlined,
  FormOutlined,
  DiffOutlined,
  CameraOutlined
} from '@ant-design/icons';
import { NavItemType } from '../types/menu';

const icons = {
  DashboardOutlined,
  GoldOutlined,
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  AudioOutlined,
  TagOutlined,
  DisconnectOutlined,
  BellOutlined,
  MediumOutlined,
  AccountBookOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  KeyOutlined,
  DollarOutlined,
  AlignLeftOutlined,
  BookOutlined,
  ProfileOutlined,
  PieChartOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
  RocketOutlined,
  TagsOutlined,
  FormOutlined,
  DiffOutlined,
  CameraOutlined
};

const custom: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'property',
      title: <FormattedMessage id="Property Listing" />,
      type: 'item',
      icon: icons.DiffOutlined,
      url: '/property/list'
    },
    {
      id: 'services',
      title: <FormattedMessage id="Service Creation" />,
      type: 'item',
      icon: icons.FormOutlined,
      url: '/services/list'
    },
    // {
    //   id: 'service-order',
    //   title: <FormattedMessage id="Service Order" />,
    //   type: 'item',
    //   icon: icons.FormOutlined,
    //   url: '/service-order'
    // }
    // {
    //   id: 'plot-for-sale',
    //   title: <FormattedMessage id="Plots for Sale" />,
    //   type: 'item',
    //   icon: icons.FormOutlined,
    //   url: '/plot-for-sale'
    // }
    // {
    //   id: 'construction-leads',
    //   title: <FormattedMessage id="Construction Leads" />,
    //   type: 'item',
    //   icon: icons.FormOutlined,
    //   url: '/construction-leads'
    // }
    // {
    //   id: 'support',
    //   title: <FormattedMessage id="Support" />,
    //   type: 'item',
    //   icon: icons.FormOutlined,
    //   url: '/support'
    // },
    {
      id: 'image-upload',
      title: <FormattedMessage id="Image Upload" />,
      type: 'item',
      icon: icons.CameraOutlined,
      url: '/image-upload'
    }
  ]
};

export default custom;
