
export interface IData {
  _id: string;
  ticketId: string;
  name: string;
  description: string;
  mobile: string;
  action: boolean;
  status: string;
  createdAt: string;
}
export enum StatusFilterEnum {
  ALL = 0,
  OPEN = 1,
  CLOSE = 2,
  PENDING = 3
}
export interface SupportState {
  List: IData[];
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  status: StatusFilterEnum;
}

export type InitialState = SupportState;
