import { AxiosResponse } from 'axios';
import CatchError from 'hooks/CatchError';
import moment from 'moment';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { deleteType, get, patch, post } from 'utilsNew/request';
import {
  selectEndDate,
  selectExcelOrderBy,
  selectExcelOrderDir,
  selectExcelPageNo,
  selectExcelPageSize,
  selectForm,
  selectOrderBy,
  selectOrderDir,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectStartDate,
  selectStatusFilter
} from './selector';
import { actions } from './slice';
import { IProperty, PropertyStatusFilterEnum } from './types';
import { propertyValidation } from 'components/Common/Validations';
import CustomSnackbar from 'components/Common/CustomSnackbar';

export function* doAddRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const form: IProperty = yield select(selectForm);
    const data: IProperty | any = {
      location: { type: 'Point', coordinates: [form?.location.lng, form.location.lat] },
      areaOfPlot: Number(form.areaOfPlot),
      nearbyLocation: form.nearbyLocation,
      pricePerSqft: Number(form.pricePerSqft),
      propertyRatings: Number(form.propertyRatings),
      priceAreaWise: Number(form.priceAreaWise),
      projectSize: Number(form.projectSize),
      plotType: form.plotType,
      amenities: form?.amenities,
      brochure: form?.brochure,
      aboutBuilder: form.aboutBuilder,
      approvals: form?.approvals,
      locationHighlights: form?.locationHighlights,
      availabilityStatus: form?.availabilityStatus,
      address: form?.address,
      images: form?.images,
      reviews: form?.reviews,
      faqs: form?.faqs,
      isActive: form?.isActive,
      propertyFacing: form.propertyFacing
    };
    const isValid = propertyValidation(data);
    if (!isValid) {
      return;
    }

    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(post, `admin/property/create`, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    CustomSnackbar(response.data.message, 'success');
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const sortColumn: string = yield select(selectOrderBy);
    const sortDir: string = yield select(selectOrderDir);
    const statusFilter: PropertyStatusFilterEnum = yield select(selectStatusFilter);
    const startDate: string = yield select(selectStartDate);
    const endDate: string = yield select(selectEndDate);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/property/list?pageNo=${pageNo}&pageSize=${pageSize}&statusFilter=${statusFilter}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${sortColumn.length > 0 ? '&orderBy=' + sortColumn : ''}${sortDir.length > 0 ? '&orderDirection=' + sortDir : ''}${
        startDate ? '&startDate=' + moment(startDate).format('YYYY-MM-DD') : ''
      }${endDate ? '&endDate=' + moment(endDate).format('YYYY-MM-DD') : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* doGetByIdRequest(action: {
  payload: {
    id: string;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(get, `admin/property/get/${action.payload.id}`);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setDataInForm(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* doUpdateRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const form: IProperty = yield select(selectForm);
    const data: IProperty | any = {
      _id: form?._id,
      location: { type: 'Point', coordinates: [form?.location.lng, form.location.lat] },
      areaOfPlot: Number(form.areaOfPlot),
      nearbyLocation: form.nearbyLocation,
      pricePerSqft: Number(form.pricePerSqft),
      priceAreaWise: Number(form.priceAreaWise),
      propertyRatings: Number(form.propertyRatings),
      projectSize: Number(form.projectSize),
      plotType: form.plotType,
      amenities: form?.amenities,
      brochure: form?.brochure,
      aboutBuilder: form.aboutBuilder,
      approvals: form?.approvals,
      locationHighlights: form?.locationHighlights,
      availabilityStatus: form?.availabilityStatus,
      address: form?.address,
      images: form?.images,
      reviews: form?.reviews,
      faqs: form?.faqs,
      isActive: form?.isActive,
      propertyFacing: form.propertyFacing
    };
    const isValid = propertyValidation(data);
    if (!isValid) {
      return;
    }
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(post, `admin/property/update/${form._id}`, data);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    CustomSnackbar(response.data.message, 'success');
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}
export function* doAddFaqRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {}
}
export function* doAddReviewRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  try {
    yield call(action?.payload?.callback());
  } catch (error: any) {}
}

export function* doDeleteRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(deleteType, `admin/property/delete/${action.payload.id}`);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    CustomSnackbar(response.data.message, 'success');
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}
export function* doChangeStatusRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(patch, `admin/property/status/update/${action.payload.id}`, {});
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}
export function* uploadDocumentRequest(action: { payload: { fileName: string; name: string; callback: any } }) {
  try {
    const response: AxiosResponse = yield call(post, `admin/property/excel/upload-file`, {
      fileName: action.payload.fileName,
      originalFileName: action.payload.name
    });
    if (!response.status) {
      CustomSnackbar(response.data.message, 'error');
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchError(error);
  }
}
//************ */
export function* doGetExcelUploadListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectExcelPageNo);
    const pageSize: number = yield select(selectExcelPageSize);
    const searchText: string = yield select(selectSearch);
    const sortColumn: string = yield select(selectExcelOrderBy);
    const sortDir: string = yield select(selectExcelOrderDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/property/excel/list?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&orderBy=' + sortColumn : ''
      }${sortDir.length > 0 ? '&orderDirection=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setExcelTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setExcelUploadList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* doGetExcelFileListRequest(action: {
  payload: {
    id: string;
  };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectExcelPageNo);
    const pageSize: number = yield select(selectExcelPageSize);
    const searchText: string = yield select(selectSearch);
    const sortColumn: string = yield select(selectExcelOrderBy);
    const sortDir: string = yield select(selectExcelOrderDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/property/excel/file/content/${action.payload.id}?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${sortColumn.length > 0 ? '&orderBy=' + sortColumn : ''}${sortDir.length > 0 ? '&orderDirection=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setExcelTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setExcelFileList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* PropertyRepoSaga() {
  yield takeLatest(actions.doAddFaq, doAddFaqRequest);
  yield takeLatest(actions.doAddReview, doAddReviewRequest);
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetById, doGetByIdRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
  yield takeLatest(actions.doChangeStatus, doChangeStatusRequest);
  yield takeLatest(actions.uploadImage, uploadDocumentRequest);
  /*********** */
  yield takeLatest(actions.doGetExcelUploadList, doGetExcelUploadListRequest);
  yield takeLatest(actions.doGetExcelFileList, doGetExcelFileListRequest);
}
