import { PlotFacingTypeEnum, PropertyAvailabilityStatusEnum, PropertyState, PropertyStatusFilterEnum } from '.';

export const initialState: PropertyState = {
  form: {
    _id: '',
    location: {
      // lat: 40.7128, // Initial Latitude
      // lng: -74.006 // Initial Longitude
      lat: 26.963615051025762, // Initial Latitude
      lng: 75.7670243899338, // Initial Longitude
      type: 'Point',
      coordinates: []
    },
    areaOfPlot: 0,
    pricePerSqft: 0,
    priceAreaWise: 0,
    projectSize: 0,
    amenities: [],
    brochure: '',
    aboutBuilder: '',
    approvals: [],
    locationHighlights: '',
    availabilityStatus: PropertyAvailabilityStatusEnum.OPEN,
    images: [],
    reviews: [],
    faqs: [],
    plotType: '',
    reviewsForm: {
      review: '',
      _id: '',
      createdAt: '',
      updatedAt: ''
    },
    faqsForm: {
      question: '',
      answer: '',
      _id: '',
      createdAt: '',
      updatedAt: ''
    },
    createdAt: '',
    isActive: true,
    propertyFacing: PlotFacingTypeEnum.EAST,
    propertyRatings: 0,
    address: '',
    propertyNo: ''
  },
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: PropertyStatusFilterEnum.ALL,
  endDate: '',
  startDate: '',
  excelList: [],
  excelPageNo: 0,
  excelPageSize: 0,
  excelTotalRow: 0,
  excelOrderBy: '',
  excelOrderDir: '',
  excelFileList: []
};
