// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import RTLLayout from './components/RTLLayout';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import { FirebaseProvider as AuthProvider } from './contexts/FirebaseContext';

import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { sliceKey as AuthSliceKey, reducer as AuthReducer } from 'sections/auth/redux/slice';
import { AuthRepoSaga } from 'sections/auth/redux/saga';

import { sliceKey as PropertySliceKey, reducer as PropertyReducer } from 'pages/PropertyListing/slice';
import { PropertyRepoSaga } from 'pages/PropertyListing/saga';

import { sliceKey as ServicesSliceKey, reducer as ServicesReducer } from 'pages/ServiceCreation/slice';
import { ServicesRepoSaga } from 'pages/ServiceCreation/saga';

import { sliceKey as SupportSliceKey, reducer as SupportReducer } from 'pages/Support/slice';
import { SupportRepoSaga } from 'pages/Support/saga';

import { sliceKey as ImageUploadSliceKey, reducer as ImageUploadReducer } from 'pages/ImagesUpload/slice';
import { ImageUploadRepoSaga } from 'pages/ImagesUpload/saga';

const App = () => {
  useInjectReducer({
    key: AuthSliceKey,
    reducer: AuthReducer
  });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  //*********** */
  useInjectReducer({
    key: PropertySliceKey,
    reducer: PropertyReducer
  });
  useInjectSaga({ key: PropertySliceKey, saga: PropertyRepoSaga });
  //************** */
  //*********** */
  useInjectReducer({
    key: ServicesSliceKey,
    reducer: ServicesReducer
  });
  useInjectSaga({ key: ServicesSliceKey, saga: ServicesRepoSaga });
  //************** */
  /******* */
  useInjectReducer({
    key: SupportSliceKey,
    reducer: SupportReducer
  });
  useInjectSaga({ key: SupportSliceKey, saga: SupportRepoSaga });
  /******* */
  /******* */
  useInjectReducer({
    key: ImageUploadSliceKey,
    reducer: ImageUploadReducer
  });
  useInjectSaga({ key: ImageUploadSliceKey, saga: ImageUploadRepoSaga });
  /******* */
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
