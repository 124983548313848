export interface IProperty {
  _id: string;
  propertyNo: string;
  plotType: string; ///
  location: ILocation; //
  areaOfPlot: number; //
  nearbyLocation?: string; //
  propertyRatings: number; //
  pricePerSqft: number; //
  priceAreaWise: number; //
  projectSize: number; //
  amenities: string[]; //
  brochure: string; //
  aboutBuilder: string; //
  approvals: PropertyApprovalTypeEnum[]; //
  locationHighlights: string; //
  availabilityStatus: PropertyAvailabilityStatusEnum; //
  images: PropertyImagesDto[];
  reviews: PropertyReviewsDto[];
  reviewsForm: PropertyReviewsDto;
  faqs: PropertyFaqsDto[];
  faqsForm: PropertyFaqsDto;
  createdAt: string;
  isActive: boolean;
  propertyFacing: PlotFacingTypeEnum;
  address: string;
}
export enum PlotFacingTypeEnum {
  NULL = 0,
  NORTH = 1,
  SOUTH = 2,
  EAST = 3,
  WEST = 4
}
export enum PropertyApprovalTypeEnum {
  HMDA = 'HMDA',
  DTCP = 'DTCP',
  GP = 'GP',
  OTHER = 'OTHER'
}

interface ILocation {
  lat: number;
  lng: number;
  type: 'Point';
  coordinates: Number[];
}

export interface PropertyImagesDto {
  _id?: string;
  file: string;
  isDeleted?: boolean;
}
export interface PropertyFaqsDto {
  question: string;
  answer: string;
  isDeleted?: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
}
export interface PropertyReviewsDto {
  _id: string;
  createdAt: string;
  updatedAt: string;
  review: string;
  isDeleted?: boolean;
}

export enum PropertyAvailabilityStatusEnum {
  NULL = 0,
  OPEN = 1,
  BOOKED = 2,
  REGISTERED = 3
}
export enum PropertyStatusFilterEnum {
  ALL = 1,
  ACTIVE = 2,
  INACTIVE = 3
}
export interface IExcelUploadList {
  _id: string;
  fileName: string;
  originalFileName: string;
  status: PropertyFilesStatusEnum;
  createdAt: string;
  updatedAt: string;
  error: any;
}

export interface IExcelUploadFileList {
  _id: string;
  propertyNo: string;
  excelFileId: string;
  location: ILocation;
  address: string;
  areaOfPlot: number;
  nearbyLocation: string;
  pricePerSqft: number;
  priceAreaWise: number;
  projectSize: number;
  plotType: string;
  amenities: [];
  brochure: string;
  aboutBuilder: string;
  approvals: [];
  locationHighlights: string;
  availabilityStatus: PropertyAvailabilityStatusEnum;
  propertyFacing: PlotFacingTypeEnum;
  status: PropertyFileDataStatusEnum;
  error: any;
  createdAt: string;
  updatedAt: string;
}
export enum PropertyFilesStatusEnum {
  PROCESSED = 1,
  PENDING = 2,
  PARTIALLY_PROCESSED = 3,
  PROCESSED_WITH_ERRORS = 4,
  PROCESSING = 5,
  FAILED = 6
}

export enum PropertyFileDataStatusEnum {
  PROCESSED = 1,
  PENDING = 2,
  FAILED = 3,
  PROCESSED_WITH_ERROR = 4
}

export interface PropertyState {
  List: IProperty[];
  form: IProperty;
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  startDate: string;
  endDate: string;
  status: PropertyStatusFilterEnum;

  excelList: IExcelUploadList[];
  excelFileList: IExcelUploadFileList[];
  excelPageNo: number;
  excelPageSize: number;
  excelTotalRow: number;
  excelOrderBy: string;
  excelOrderDir: string;
}

export type InitialState = PropertyState;
