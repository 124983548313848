import { StatusFilterEnum, SupportState } from '.';

export const initialState: SupportState = {
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 0,
  pageSize: 0,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: StatusFilterEnum.ALL
};
