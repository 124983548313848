import { dispatch } from 'store';
import { ILogin, IPassword } from 'sections/auth/redux/types';
import { openSnackbar } from 'store/reducers/snackbar';
import { emailRegex } from 'utilsNew/constants/Email';
import { passwordRegex } from 'utilsNew/constants/Password';
import { IProperty } from 'pages/PropertyListing/types';
import CustomSnackbar from '../CustomSnackbar';
import { IServices } from 'pages/ServiceCreation/types';

export const loginValidation = (form: ILogin) => {
  if (form?.email?.length == 0) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  if (form?.email?.length > 0 && !emailRegex.test(form?.email)) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter valid email',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  if (form?.password?.length == 0) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  return true;
};
export const updatePasswordValidation = (form: IPassword) => {
  if (form?.password?.length == 0) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  if (form?.password?.length > 0 && !passwordRegex.test(form?.password)) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter valid password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  if (form?.confirmPassword?.length == 0) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter confirm password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  if (form?.confirmPassword?.length > 0 && !passwordRegex.test(form?.confirmPassword)) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'Please enter valid confirm password',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  if (form?.password !== form?.confirmPassword) {
    dispatch(
      openSnackbar({
        open: true,
        message: 'password and confirm password must be the same',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return false;
  }
  return true;
};
export const propertyValidation = (form: IProperty) => {
  if (form?.areaOfPlot == 0) {
    CustomSnackbar('Please enter area of plot', 'error');
    return false;
  }
  if (!form?.nearbyLocation || form?.nearbyLocation?.length == 0) {
    CustomSnackbar('Please enter near by location', 'error');
    return false;
  }
  if (form?.priceAreaWise == 0) {
    CustomSnackbar('Please enter price area wise', 'error');
    return false;
  }
  if (form?.pricePerSqft == 0) {
    CustomSnackbar('Please enter price per Sq ft', 'error');
    return false;
  }
  if (form?.projectSize == 0) {
    CustomSnackbar('Please enter project size', 'error');
    return false;
  }
  if (form?.plotType.length == 0) {
    CustomSnackbar('Please enter plot type', 'error');
    return false;
  }
  if (form?.amenities.length == 0) {
    CustomSnackbar('Please enter amenities', 'error');
    return false;
  }
  if (form?.aboutBuilder.length == 0) {
    CustomSnackbar('Please enter about builder', 'error');
    return false;
  }
  if (form?.locationHighlights.length == 0) {
    CustomSnackbar('Please enter location highlights', 'error');
    return false;
  }
  if (form?.propertyRatings == 0) {
    CustomSnackbar('Please enter property ratings', 'error');
    return false;
  }
  if (form?.propertyFacing == 0) {
    CustomSnackbar('Please select property facing', 'error');
    return false;
  }
  if (form?.availabilityStatus == 0) {
    CustomSnackbar('Please select availability status', 'error');
    return false;
  }
  if (form?.approvals?.length == 0) {
    CustomSnackbar('Please select approvals', 'error');
    return false;
  }
  if (form?.faqs.length == 0) {
    CustomSnackbar('Please fill faqs', 'error');
    return false;
  }
  if (form?.reviews.length == 0) {
    CustomSnackbar('Please fill reviews', 'error');
    return false;
  }
  if (form?.brochure.length == 0) {
    CustomSnackbar('Please upload brochure', 'error');
    return false;
  }
  if (form?.images.length == 0) {
    CustomSnackbar('Please upload images', 'error');
    return false;
  }
  return true;
};
export const servicesValidation = (form: IServices) => {
  if (form?.description?.length == 0) {
    CustomSnackbar('Please enter description', 'error');
    return false;
  }
  if (form?.highlights?.length == 0) {
    CustomSnackbar('Please enter highlights', 'error');
    return false;
  }
  if (form?.fee == 0) {
    CustomSnackbar('Please enter fee', 'error');
    return false;
  }
  if (form?.images.length == 0) {
    CustomSnackbar('Please upload images', 'error');
    return false;
  }
  return true;
};
